:any-link,
button
  @apply cursor-none

.cursor
  @apply bg-black
  .background-black &
    @apply bg-white
  .background-white &
    @apply bg-black
  .background-orange &
    @apply bg-black

.cursor-active
  @apply scale-100