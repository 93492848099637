video
  &::-webkit-media-controls-panel
    display: none!important
    -webkit-appearance: none
  &::--webkit-media-controls-play-button
    display: none!important
    -webkit-appearance: none
  &::-webkit-media-controls-start-playback-button
    display: none!important
    -webkit-appearance: none


.video

  &--wrapper

    .video--loop
      @apply opacity-100 visible
    .video--video
      @apply opacity-0 invisible

    .video--play-trigger-icon
      .play
        @apply opacity-100
      .stop
        @apply opacity-0

    &.is-playing
      // .video--loop,
      // .video--play-trigger
      .video--loop
        @apply opacity-0 invisible
      .video--video
        @apply opacity-100 visible

      .video--play-trigger-icon
        .play
          @apply opacity-0
        .stop
          @apply opacity-100
