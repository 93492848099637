.footer

  &--button
    --circle-radius: 5%
    --circle-x: 0
    --circle-y: 0
    .footer--button-deco
      clip-path: circle(var(--circle-radius) at var(--circle-x) var(--circle-y))

    // &:after
    //   content: ''
    //   @apply absolute top-0 left-0 w-full h-full z-30

    // &:hover
    //   .footer--button-deco
    //     @apply opacity-100