.projects

  &-filter-link
    &:not(.active)
      // @apply cursor-pointer
      @apply bg-white hover:border-orange hover:bg-orange transition-colors

    &.active
      @apply bg-orange border-orange


.project-teaser

  // figure
  //   @apply overflow-hidden
  // figure img
  //   @apply transition-transform duration-200 scale-100 origin-center

  // &:hover
  //   figure img
  //     @apply scale-105

  .project-title
    @apply transition-all duration-200
  // &:hover
  //   .project-title
  //     @apply border-orange text-black bg-orange