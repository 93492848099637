#cookie-modal
  @apply w-full md:w-auto h-auto top-auto left-auto bottom-0 right-0
  @apply max-w-[500px]

  .cookie-modal__content
    @apply p-4 md:p-6 pt-3 md:pt-4 m-0 shadow-none

    .background-white &,
    .background-orange &
      @apply bg-black
    .background-black &
      @apply bg-white

  .cookie-modal__title
    @apply font-adieu font-normal
    @apply text-black
    @apply text-cookie_title_m md:text-cookie_title_t xl:text-cookie_title_d 3xl:text-cookie_title_w
    @apply mb-4 md:mb-6

  .cookie-modal__text
    @apply text-cookie_text_m md:text-cookie_text_t xl:text-cookie_text_d 3xl:text-cookie_text_w
    @apply mb-4 md:mb-6

  .cookie-modal__title,
  .cookie-modal__text,
  .cookie-modal__label
    .background-white &,
    .background-orange &
      @apply text-white
    .background-black &
      @apply text-black

  .cookie-modal__label
    @apply text-cookie_text_m md:text-cookie_text_t xl:text-cookie_text_d 3xl:text-cookie_text_w

  .cookie-modal__checkbox
    @apply border md:border-2
    .background-white &,
    .background-orange &
      @apply border-white
    .background-black &
      @apply border-black

  .cookie-modal__checkbox ~ .cookie-modal__check svg
    @apply hidden
  .cookie-modal__checkbox:checked ~ .cookie-modal__check svg
    @apply block

  .cookie-modal__button
    @apply font-adieu uppercase tracking-widest
    @apply text-white
    @apply border xl:border-2 rounded-full
    @apply flex items-center justify-center
    @apply whitespace-nowrap
    @apply text-cookie_buttons_m xl:text-cookie_buttons_d 3xl:text-cookie_buttons_w
    @apply px-3 md:px-4 xl:px-6 min-w-[70px] md:min-w-[100px]

    &.hide
      @apply hidden

    .background-white &,
    .background-orange &
      @apply text-white border-white
      @apply hover:text-black hover:border-white hover:bg-white
    .background-black &
      @apply text-black border-black
      @apply hover:text-white hover:border-black hover:bg-black


  // .cookie-modal__button.primary
  //   .background-white &,
  //   .background-orange &
  //     @apply text-black border-white bg-white
  //   .background-black &
  //     @apply text-white border-black bg-black

  .cookie-modal__label
    @apply cursor-none
