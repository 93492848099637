@import 'fonts';
@import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import 'footer';
@import 'navigation';
@import 'img';
// @import 'ajax';
// @import 'button';
// @import 'drupal';
// @import 'eu-cookie';
@import 'contact';
@import 'cookie-modal';
@import 'cursor';
@import 'editor';
@import 'scrollup';
@import 'projects';
@import 'video';
@import 'lottie';
// @import 'embed';
// @import 'form';
// @import 'grid';
// @import 'header';
// @import 'hover';
// @import 'img';
// @import 'link';
// @import 'linkteaser';
// @import 'media';
// @import 'newsletter';
// @import 'post';
// @import 'search';
// @import 'shop';
// @import 'teaser';
// @import 'video';

@import "./vendor/tiny-slider.css"
