.navigation

  &--trigger
    @apply w-16 h-16 fixed z-30 top-0 md:top-2 right-0 md:right-4
    z-index: 100
    border: none
    background: none
    padding: 0
    outline: none !important

    @apply text-black hover:text-orange
    // @apply text-black
    .navigation__active &
      @apply hover:text-white
    body.bg-orange &
      @apply text-black hover:text-white
      // @apply text-black
    body.bg-black &
      @apply text-white hover:text-orange
      // @apply text-white
    .navigation__active body.bg-black &
      @apply text-black hover:text-white
      // @apply text-black

    // @apply transition-opacity opacity-100 duration-200
    // .scrolling &
    //   @apply opacity-0

    &-deco-01,
    &-deco-02
      content: ''
      @apply block absolute top-8 right-3 w-10 h-[1px] md:h-[2px] transition-all duration-200 origin-center
      @apply bg-current

    &-deco-01
      @apply rotate-0
    &-deco-02
      @apply rotate-90

    .navigation__active &
      &-deco-01
        @apply rotate-45
      &-deco-02
        @apply -rotate-45
