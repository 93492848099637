.editor

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply underline decoration-1 md:decoration-2 3xl:decoration-[3px]
    @apply underline-offset-[4px] md:underline-offset-[6px] xl:underline-offset-[8px]
    @apply transition-colors duration-200
    .bg-white &
      @apply text-black hover:text-orange
    .bg-orange &
      @apply text-black hover:text-white
    .bg-black &
      @apply text-white hover:text-orange

  .text-copy_sm_m
    a
      @apply md:decoration-1 xl:decoration-2
      @apply underline-offset-[3px] md:underline-offset-[3px] xl:underline-offset-[4px]

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0